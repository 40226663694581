<template>
    <div>
        <v-overlay
            :value="loader"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    />
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :headers="headers"
            :items="items"
            :no-data-text="$t('message.noNotificationHistoryHere')"
            class="appic-table-light specification-table"
            dense
            item-key="Notification.senddate"
        >
            <template v-slot:top>
                <div class="flex flex-row align-center justify-space-between">
                    <span class="font-italic font-xs mt-2 mb-0">{{ $t('message.noteNotificationHistory') }}</span>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon @click="reloadNotifications()" v-on="{ ...tooltip }" :loading="loading">
                                <v-icon>refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.reload') }}</span>
                    </v-tooltip>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import {formatDate} from "Helpers/helpers";
    import {api} from "Api";

    export default {
        name: "NotificationsLog",
        props: ['asset','asset_id'],
        data() {
            return {
                items: [],
                loader: false,
                loading: false
            }
        },
        computed: {
            formatDate: () => formatDate,
            headers() {
                return [
                    {
                        id: 1,
                        text: this.$t('message.sentDate'),
                        value: 'Notification.senddate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 2,
                        text: this.$t('message.recipients'),
                        value: 'Notification.recipients',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 3,
                        text: this.$t('message.message'),
                        value: 'Notification.message',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 4,
                        text: this.$t('message.sender'),
                        value: 'Notification.sender',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                ]
            },
        },
        methods: {
            replaceText (text) {
                if(this.textReplace?.length > 0){
                    this.textReplace.forEach(obj => {
                        Object.entries(obj).forEach(entry => {
                            const [key, value] = entry
                            text = text.replace(key,value)
                        })
                    })
                    return text
                } else {
                    return text
                }
            },
            loadNotifications() {
                this.loader = true
                this.loading = true
                api
                    .get("/notifications/" + this.asset + "/" + this.asset_id)
                    .then(response => {
                        this.items = response.data.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(()=>{
                        this.loader = false
                        this.loading = false
                    })
            },
            reloadNotifications() {
                this.loadNotifications()
            }
        },
        created(){
            this.loadNotifications()
        }
    }
</script>

<style>
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>